import React from "react"
import "./about-us.scss"
import { Link } from 'gatsby'
import Image from "../image-component/image-new"
import Breadcrumbs from "../breadcrumbs/breadcrumbs"

const pageContent = {
    "heroHeading": "We are <span>Spiralyze</span>",
    "storyParagraph": [
        "Spiralyze uses data-driven design to increase your conversion rate, creating massive revenue growth.",
        "Our process produces an ongoing source of conversion and revenue gains. Through research, we develop insights into the root causes of missed sales. We turn those insights into designs that persuade more of your web visitors to make purchases. We subject every design to the discipline of A/B testing to measure the impact of every change.",
        "We bring a wealth of client experience, enabling us to drive more impact and faster impact. Our leadership team brings digital conversion thought leadership from McKinsey, Google, and eBay."
    ],
    "pricingSection": {
        "pricingHeading": "Want to learn more?",
        "pricingSubheding": "Find out how Spiralyze can help Grow your revenue",
        "ctaCopy": "Get Pricing"
    }
}

export default function AboutUs() {
    return (
        <span className="aboutus-wrap">
            <Breadcrumbs currentPage={'about us'} />
            <div className="our-story-wrapper">
                <div className="container">
                    <h2 className="our-story-heading" dangerouslySetInnerHTML={{ __html: pageContent.heroHeading }} />
                    <div className="our-story-image-wrapper">
                        <Image imgName={'team_work.png'} sourceFolder={'aboutus'} alt={"About us"} />
                    </div>
                    <div className="our-story-body">
                        {pageContent.storyParagraph.map((item, index) => {
                            return <p key={index}>{item}</p>
                        })}
                    </div>
                </div>
            </div>
            <div className="pricing-learnmore">
                <div className="container">
                    <h2 className="pl-title">{pageContent.pricingSection.pricingHeading}</h2>
                    <p className="pl-subtitle">{pageContent.pricingSection.pricingSubheading}</p>
                    <Link to="/pricing/" className="cta-big-filled pl-gp-btn">{pageContent.pricingSection.ctaCopy}</Link>
                </div>
            </div>
        </span>
    )
}