import * as React from "react"
import Layout from "../components/layout"
import AboutUs from "../components/about-us/about-us"
import SeoNoIndex from "../components/seo-no-index-ablyft"

const Pricing = () => (
  <Layout>
    <SeoNoIndex title="About us" index={false} follow={false} />
    <AboutUs />
  </Layout>
)

export default Pricing
